#privacyPolicyContainer * {
  all: revert;
}
#privacyPolicyContainer {
  color: white;

  height: 100vh;
  overflow-y: scroll;
}
#privacyPolicyContainer > div {
  margin-inline: 30vw;
}
