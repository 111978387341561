:root {
  --main-color: #06c;
  --off-white: rgba(253, 245, 241, 1);
  --off-white-hovered: rgba(241, 235, 232, 255);
  --off-white-pressed: rgba(196, 196, 196, 0.2);
  --off-white-pressed2: rgba(196, 196, 196, 0.3);

  --coffee-brown: rgba(96, 70, 59, 1);
  --coffee-beige: rgba(230, 204, 178, 1);
  --coffee-orange: rgba(255, 160, 92, 1);
  --coffee-blue: #62b6cb;
  --coffee-red: #8d3f3f;
  --coffee-pink: #cea1a1;
  --coffee-green: #73a580;

  --default-placeholder-text-color: #757575;

  --dark-mode-disabled-text: rgba(14, 14, 14, 0.6);

  --background: rgba(14, 14, 14, 1);
  --on-background: rgba(255, 255, 255, 1);

  --surface: rgba(255, 255, 255, 0.06);
  --on-surface: rgba(255, 255, 255, 0.87);
  --on-surface-medium: rgba(255, 255, 255, 0.6);
  --on-surface-disabled: rgba(255, 255, 255, 0.38);

  --primary: rgba(255, 160, 92, 1);
  --on-primary: rgba(14, 14, 14, 1);

  --secondary: #cea1a1;
  --on-secondary: rgba(18, 18, 18, 1);

  --hover: rgba(255, 255, 255, 0.04);
  --tap: rgba(255, 255, 255, 0.1);

  --error: #cf6679;
  --disabled: rgba(255, 255, 255, 0.38);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: normal;
}

@font-face {
  font-display: swap;
  font-family: 'CormorantGaramond-Light';
  src: local('CormorantGaramond-Light'),
    url(../assets/fonts/CormorantGaramond-Light.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'),
    url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'),
    url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter-Thin';
  src: local('Inter-Thin'),
    url(../assets/fonts/Inter-Thin.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter-Light';
  src: local('Inter-Light'),
    url(../assets/fonts/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Inter-ExtraLight';
  src: local('Inter-ExtraLight'),
    url(../assets/fonts/Inter-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'InterTight-Regular';
  src: local('InterTight-Regular'),
    url(../assets/fonts/InterTight-Regular.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'InterTight-Light';
  src: local('InterTight-Light'),
    url(../assets/fonts/InterTight-Light.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Kaisei Haruno Umi';
  src: local('KaiseiHarunoUmi-Regular'),
    url(../assets/fonts/KaiseiHarunoUmi-Regular.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Lato';
  src: local('Lato-Regular'),
    url(../assets/fonts/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  src: local('Montserrat-Light'),
    url(../assets/fonts/Montserrat-Light.ttf) format('truetype');
}

::-moz-selection {
  background-color: var(--off-white);
  color: black;
}
::selection {
  background-color: var(--off-white);
  color: black;
}

* {
  scroll-behavior: smooth;
}

video::-webkit-media-controls,
video::-moz-media-controls,
video::-o-media-controls,
video::-ms-media-controls {
  display: none !important;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-inline: 6px;
  margin-bottom: 2px;
}
p {
  margin: 0;
}

body {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--background);
  /* background-size: cover; */
}

.app-container {
  position: relative;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;

  /* min-width: 580px; */
  width: 40vw;
}

.links {
  margin-top: 6px;

  gap: 20px;
}
.links a {
  color: var(--on-surface-medium);
}
.left {
  justify-content: flex-start;
  margin-inline: 4px;
}
.right {
  justify-content: flex-end;
  margin-inline: max(2.5vw, 40px);
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.header-menu-item {
  z-index: 2;
  margin-top: 44vh;
  color: var(--on-background);
  font-size: 1.4rem;
  letter-spacing: -0.08rem;
  font-family: 'Inter-Thin', Arial, Helvetica, sans-serif;
  cursor: pointer;
  /* padding: 3rem; */
  /* padding-inline: 1.6em; */
  padding-inline: 1.82vw;
}

.testone {
  background-color: var(--surface);
}

.header-menu-content {
  width: max(40vw, 630px);
  /* max-width: 780px; */
  /* min-width: 312px; */
  height: 100%;
  display: flex;
  position: absolute;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  overflow-x: hidden;
}

.header-menu-content-space-maker {
  width: max(40vw, 630px);
  /* max-width: 780px; */
  /* min-width: 312px; */
  height: 100vh;
  display: flex;
}

.slide-transformer0 {
  transform: translateX(0%);
}
.slide-transformer1 {
  transform: translateX(min(-40vw, -630px));
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.header-skills {
  margin-bottom: 0.4vw;
  color: var(--on-surface);
}

.skills-container {
  justify-self: flex-end;
  margin-left: 20px;
  padding-inline: 4px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

/* Don't know what this is lmao */
.item {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

/* Don't know what this is lmao */
.outline {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  border: 10px solid white;
  border-radius: 50%;
}

.language-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 20px;
  height: 20px;
  margin: auto;
  z-index: 100;
}

@media only screen and (max-width: 768px) {
  body {
    overflow-y: hidden;
    margin: 0;
    padding: 0;

    /* --background + --on-surface */
    /* background-color: #1c1c1c; */
  }

  .slide-transformer1 {
    transform: translateX(-100vw);
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: baseline;

    /* height: 100vh; */
    width: 100vw;
  }

  .header-menu-item {
    z-index: 2;
    margin-top: auto;
    margin-bottom: 0;
    color: var(--on-background);
    mix-blend-mode: difference;
    font-size: 1.4rem;
    letter-spacing: -0.08rem;
    font-family: 'Inter-Thin', Arial, Helvetica, sans-serif;
    cursor: pointer;
    padding: 1.6rem;
    /* padding-inline: 1.6em; */
    padding-inline: 1.82vw;
    width: 100%;
    text-align: center;
  }

  .header-menu-content {
    width: 100vw;
    /* max-width: 780px; */
    /* min-width: 312px; */
    height: 100%;
    margin-bottom: 80px;
    display: flex;
    position: absolute;
    /* padding-bottom: 90px; */

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */

    overflow-x: hidden;
  }

  .header-menu-content-space-maker {
    display: none;
    /* width: 72vw;
    max-width: 780px;
    min-width: 312px;
    height: 100vh;
    display: flex; */
  }
}
