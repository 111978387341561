.about-container {
  background-color: #fffff2;
  height: calc(100vh);
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  font-family: 'CormorantGaramond-Light';
  padding-left: clamp(10px, 4vw, 60px);
}

.about-container a {
  color: inherit;
  text-decoration: inherit;
}

.about-header {
  padding-top: 15px;
  padding-bottom: 5px;
}

.about-container h1 {
  padding-right: 275px;
  color: black;
}
.me {
  pointer-events: none;
  user-select: none;
  position: absolute;
  z-index: 1;
  bottom: 0;
  /* left: 25%; */
  right: -38vw;
  /* right: 50%; */
}
.me img {
  /* width: clamp(410px, 40.2vw, 760px); */
  object-fit: contain;
  object-position: bottom;
  /* height: 72%; */
  width: 92vw;
  max-height: 70vh;

  /* height: 72vh; */
  vertical-align: middle;
}

.peepoTalks {
  /* max-height: 60vh; */
  padding-right: 150px;
  /* margin-left: 40px; */
  margin-top: 10px;
  gap: 10px;
  font-weight: bold;
  font-size: clamp(1em, 1vw, 1.5em);
  height: 70vh;
}
.peepoTalks div {
  width: 15vw;
  min-width: 155px;
}

.about-languages {
  position: fixed;
  bottom: 15px;
  z-index: 20;
}

.more {
  position: absolute;
  top: 42%;
  left: 50%;
  /* border: 2px solid blue; */

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  font-family: 'Cormorant SC';
  font-size: max(1vw, 1.5em);
  z-index: 10;
  /* outline: 1px solid red; */
}

.more-text {
  width: 15vw;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more-icons {
  /* height: 48px; */
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
}
.more-icon-github {
}
.more-icon-phone {
  margin-left: -4px;
}
.more-icon-e-mail {
  margin-left: -5px;
}
.more-icon {
  margin-top: 9px;
}
.about-container p {
  padding-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .about-container {
    background-color: #fffff2;
    height: calc(100vh - 71px); /* FakeFooter height */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* bottom: 70px; */
    /* padding-bottom: 70px; */

    font-family: 'CormorantGaramond-Light';
    /* padding-left: 60px; */
    padding-left: clamp(10px, 4vw, 60px);
  }

  .peepoTalks {
    /* margin-left: 40px; */
    margin-top: 10px;
    gap: 10px;
    font-weight: bold;
  }
  .peepoTalks p {
    font-size: clamp(0.9em, 4vw, 1.3em);
    margin-bottom: 120px;
  }
  .peepoTalks div {
    width: 100vw;
  }

  .more-text {
    width: 40vw;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .me {
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: 1;
    /* left: 25%; */
    left: 18%;
    /* right: 80%; */
  }
  .me img {
    /* width: clamp(410px, 40.2vw, 760px); */
    object-fit: contain;
    object-position: bottom;
    /* height: 72%; */
    width: 120vw;
    max-height: 70vh;

    /* height: 72vh; */
    vertical-align: middle;
  }
}
