.description-and-arrow {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding-inline: 30px;

  padding-top: 15px;
}
.description-and-arrow-underneath {
  cursor: pointer;
  justify-content: flex-start;
  padding-left: 30px;
  /* padding-right: 10px; */
  /* width: 26vw; */
  width: 60%;
  font-weight: 100;
  font-family: 'Inter-Thin';
}

.description {
  font-weight: 100;

  margin: 0;
  margin-bottom: clamp(7px, 0.7vw, 12px);
  color: var(--on-background);
}
.description-arrow-underneath {
  margin-bottom: clamp(8px, 0.9vw, 12px);
}

.arrow-container {
  /* width: 100px; */
  /* height: 40px; */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow {
  /* transform-origin: left center; */
  margin-bottom: 15px;
  margin-right: 1.9vw;
  padding-left: 4px;
  margin-left: 16px;
}

.arrow-line {
  width: 10vw;
  background: var(--on-surface);
  height: 3px;
}

.arrow-point {
  height: 16px;
  width: 16px;
  border-style: solid;
  border-color: var(--on-surface);
  border-width: 0px 3px 3px 0px;
  border-radius: 3px;
  transform: rotate(-45deg);
  margin-top: -11px;
  float: right;
}

.arrow-underneath {
  margin-top: 22px;
  padding-right: 30%;
  /* transform-origin: left center; */
}

.arrow-line-underneath {
  background: var(--on-surface);
  height: 3px;
}

.arrow-point-underneath {
  height: 16px;
  width: 16px;
  border-style: solid;
  border-color: var(--on-surface);
  border-width: 0px 3px 3px 0px;
  border-radius: 3px;
  transform: rotate(-45deg);
  margin-top: -11px;
  float: right;
}

@media only screen and (max-width: 768px) {
  .description-and-arrow-underneath {
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 30px;

    width: 50vw;
    font-weight: 100;
    font-family: 'Inter-Thin';
  }
}
