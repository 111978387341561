.promptly {
  padding-top: 96px;
  margin-top: min(10px, 0.8vw);
  font-family: 'InterTight-Regular';
  font-weight: 100;
}

.promptly a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  cursor: pointer;

  z-index: 5;
}
.promptly a h1 {
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);

  padding: 0;
  margin: 0;

  font-size: max(7vw, 6.5em);
  margin-bottom: min(-1.5vw, -21px);
  font-weight: lighter;
  color: var(--on-background);
}

.promptly-prototype-and-header-container {
  margin-inline: 20px;
}

.promptly-header {
  font-family: 'InterTight-Regular';
  display: flex;
  justify-content: flex-end;
  color: var(--on-background);
  align-items: flex-end;

  margin-left: max(12px, 1.1vw);
  margin-right: max(12px, 0.3vw);
}
.promptly-header a {
  margin-left: max(28px, 1.1vw);
  justify-self: flex-start;
  left: 0;
  position: absolute;
}

.figma-prototype-container {
  position: relative;
  padding-top: 58.5%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  /* margin-right: 4px; */
  border-radius: 5px;
}
.figma-prototype-container h5 {
  position: absolute;
  margin: 0;
  padding-inline: 39px;
  padding-top: 5px;
  font-size: 0.7em;
  font-weight: 600;
  bottom: 0;
  right: 0;
  left: 0;
  color: var(--on-secondary);
  z-index: 10;
}
.figma-prototype-container iframe {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.figma-prototype-bg {
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 1px;
  position: absolute;
  content: '';
  height: 99.99%;
  width: 99.99%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: black;
}
.figma-prototype-bg video {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.clicker {
  z-index: 4 !important;
  pointer-events: all !important;
}

.promptlyDescription {
  font-family: 'InterTight-Light';
}

.small-arrow-and-links-container {
  justify-content: space-between;
}
.description-links-container {
  margin-top: 2px;
}
.small-arrow-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
}
.small-arrow {
  width: 100%;
  /* margin-right: 35px; */
  /* width: 80px; */
  /* padding-top: 10px; */
}

.small-arrow-line {
  background: var(--on-surface);
  height: 2px;
}

.small-arrow-point {
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: var(--on-surface);

  border-radius: 2px;
  transform: rotate(45deg);
  margin-top: -6px;
}

.come-mingle {
  text-align: center;
  margin: 0 auto;
}
.come-mingle h4 {
  padding-top: 30px;
  margin: 0;
  font-family: 'InterTight-Regular';
  font-size: 2.5em;
  font-weight: 200;
  color: var(--on-background);
}

.promptly .links a {
  color: var(--on-surface-medium);
  font-size: clamp(0.9em, 1.32vw, 1.1em);
}

@media only screen and (max-width: 768px) {
  .promptly a h1 {
    font-size: max(2.5em, 17.5vw);
    margin-bottom: max(-32px, -3.6vw);
  }
  .description-links-container {
    margin-top: 3px;
  }
}
