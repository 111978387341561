.text-box {
  overflow-y: scroll;
  height: max(8.9vw, 140px);
  background-color: var(--background);
  margin-inline: 22px;

  font-family: 'CormorantGaramond-Light';
}
/* Subtitle */
.text-box h2 {
  position: sticky;
  height: 27px;
  top: 0;
  margin: 0;
  padding-top: 14px;
  padding-inline: 18px;

  left: 0;
  right: 0;
  color: var(--on-surface-medium);
  background-color: var(--background);

  font-weight: 100;
  font-size: clamp(0.9em, 5vw, 1.1em);
}
/* Text body */
.text-box p {
  z-index: 2;
  padding-inline: 18px;
  margin-bottom: 10px;
  /* padding-top: 8px; */
  color: var(--on-surface);
  font-size: clamp(1em, 5.2vw, 1.38em);
}
