.timeline {
  /* padding: 20px; */
  padding-bottom: 0;
  height: 70px;
  justify-content: space-between;
  position: relative;
  justify-content: center;
}
.timeline-dot-container {
  font-family: 'CormorantGaramond-Light';

  /* width: 35px; */
  width: clamp(18px, 6vw, 42px);

  align-items: center;
  color: var(--on-surface);
}

.timeline-dots-line {
  height: 2px;
  border-radius: 50px;
  background: repeating-linear-gradient(
    to right,
    var(--on-surface-medium),
    var(--on-surface-medium) 1px,
    #00000000 3px,
    #00000000 8px
  );
  background-size: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* width: 290px; */
  width: clamp(158px, 32vw, 390px);
  position: absolute;
  margin-top: 37px;
}
.timeline-dot {
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(226, 226, 226, 255);
  margin-bottom: 10px;
}
.timeline-dot:after {
  content: '';
}

.text-box-triangle-container {
  height: 30px;
}
.text-box-triangle {
  height: 0;
  width: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;

  border-top: 18px solid var(--background);
}
