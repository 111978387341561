.skill-icon-and-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  position: relative;
}

/* name */
.skill-icon-and-name div:not(.skillbar, .tooltip) {
  margin-top: 15px;
  margin-left: 13px;
  height: 190px;
  position: absolute;
  line-height: 0.9em;
  /* padding-top: 30px; */
  writing-mode: vertical-rl;
  font-size: 0.9em;
  /* margin-left: -4px; */
}

.skillbar {
  position: relative;
  width: 8px;
  /* border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    border-color: transparent; */
  background-color: var(--background);
  border-radius: 50px;
  margin-top: 9px;
  height: 300px;
}
.skillbar::before {
  content: '';
  width: 100%;
  position: absolute;
  border-radius: 50px;
  bottom: 0;
  z-index: 19;
}

.rating-max::before {
  height: 100%;
  background-color: var(--coffee-blue);
}
.rating-high::before {
  height: 75%;
  background-color: var(--coffee-red);
}
.rating-mid::before {
  height: 50%;
  background-color: var(--coffee-red);
}
.rating-low::before {
  height: 25%;
  background-color: var(--coffee-red);
}
